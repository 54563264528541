<template>
  <div class="flex flex-col">
    <div><router-link :class="{ 'router-link-exact-active': $route.name === 'missaoIdEs' }" to="/es"
        class="gpx router-link-active">{{ $t('menu.missao') }}</router-link></div>
    <div><router-link to="/es/lugares" class="gpx">{{
      $t('menu.locais')
    }}</router-link></div>
    <div><router-link to="/es/nidos" class="gpx">{{ $t('ninhos.title') }}
      </router-link></div>
    <div><router-link to="/es/pkm90" class="gpx">
      {{ $t('menu.radar') }}
      </router-link></div>
    <div><router-link to="/es/rocket" class="gpx">>{{ $t('menu.rocket') }}</router-link></div>
    <div><router-link to="/es/raids" class="gpx">{{ $t('menu.raids') }}
      </router-link></div>
    <div><router-link to="/es/limpio" class="gpx">{{
      $t('menu.limpar')
    }}</router-link></div>
    <div><router-link to="/es/eventos" class="gpx">{{ $t('menu.eventos') }}
        <!-- <div class="event">{{ $t('missao.novo') }}</div> -->
      </router-link></div>

    <div><router-link to="/es/gpx" class="gpx">{{ $t('menu.gpx') }}</router-link></div>


    <div><router-link to="/es/videos" class="gpx">{{ $t('menu.videos') }}
        <!-- <div class="event">{{ $t('missao.novo') }}</div> -->
      </router-link></div>
    <div><router-link to="/es/socios" class="gpx">{{ $t('menu.parceiros') }}</router-link></div>
    <!-- <div class="gpx1" /> -->
  </div>
</template>

<script>
import { AppLauncher } from '@capacitor/app-launcher'

export default {
  name: 'App',
  props: ['remote'],
  data: () => ({
    open: false
  }),
  methods: {
    touchHandler() {
      this.$router.push('/gpx')
    },
    async checkCanOpenUrl() {
      const value = await AppLauncher.openUrl({ url: 'https://play.google.com/store/apps/details?id=br.com.sistemaweb.gpxgo' })
      console.log('Can open url: ', value)
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #fff;
}

.router-link-exact-active {
  background-color: #340785;
}

#nav {
  margin: 10px 0 0 0;
  width: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-wrap: nowrap;

  a {
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.router-link-exact-active {
      color: #673bb7;
    }
  }

  .gpx {
    color: #673bb7;
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 90px !important;
    margin-right: 10px;
    border-radius: 5px;
    display: inline-grid;

    &.router-link-exact-active {
      background: #673bb7;
      color: #fff;

      .event {
        color: #fff;
        background: #ffc107;
      }
    }
  }
}

.n {
  font-size: 12px;
}

#nav-2,
#nav-3 {
  padding: 5px 0 0 0;
  display: flex;
  justify-content: space-between;

  a {
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.router-link-exact-active {
      color: #673bb7;
    }
  }

  .gpx {
    color: #673bb7;
    background: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    width: 85px;
    border-radius: 5px;
    position: relative;

    &.router-link-exact-active {
      background: #673bb7;
      color: #fff;

      .event {
        color: #fff;
        background: #ffc107;
      }
    }
  }

  .gpx1 {
    width: 85px;
  }
}

.event {
  content: '50%';
  position: absolute;
  top: 5px;
  right: -10px;
  text-align: center;
  padding: 1px 0;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  text-shadow: 0 0 3px rgb(0 0 0 / 50%);
  transform: rotate(45deg);
  box-shadow: 0 0 3px hsl(0deg 0% 100% / 54%);
  width: 42px;
  font-size: 7px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #673bb7;
  text-transform: uppercase;
}

.breve {
  background: #eee !important;
}
</style>